/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/destructuring-assignment */
import { h } from 'preact';
import { imageUrl } from '_helpers';
// eslint-disable-next-line import/extensions
import style from './style';

export default (props) => (
  <div className={`${style.imagetextbox}`} id={props.id}>
    <div className={`${style.container}`}>
      {props.title ? <h1>{props.title.text}</h1> : null}

      {props.textbox ? (
        <div className={`${style.textbox}`}>
          <div className={props.textbox.reversed ? `${style.reversed}` : ''}>
            {props.textbox.image ? (
              <div className={`${style.imgcon}`}>
                <img src={imageUrl(props.textbox.image.src)} />
              </div>
            ) : null}
            <div className={`${style.lefttext}`}>
              <h2>{props.textbox.title}</h2>
              <p>{props.textbox.text}</p>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  </div>
);
