/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { h, render, Component } from 'preact';
import { connect } from 'unistore/preact';
import { imageUrl } from '_helpers';
// eslint-disable-next-line import/extensions
import style from './style';

class LatestArticles extends Component {
	componentDidMount = () => {
	  console.warn('LatestArticles data >>', this.props);
	};

	componentDidUpdate = () => {
	  console.warn('LatestArticles data >>', this.props);
	};

	parseDate = (date) => {
	  const d = new Date(date);
	  let month = `${d.getMonth() + 1}`;
	  let day = `${d.getDate()}`;
	  const year = d.getFullYear();

	  if (month.length < 2) {
	    month = `0${month}`;
	  }
	  if (day.length < 2) {
	    day = `0${day}`;
	  }

	  return [year, month, day].join('/');
	};

	render = (props) => {
	  return (
	    <div className={`${style.latestarticles}`}>
	      <div className={`${style.container}`}>
	        <div>
	          {this.props.posts.data
	            ? this.props.posts.data.map((post, i) => {
	              if (i + 1 <= this.props.itemsToDisplay) {
	                return (
	                  <div className={`${style.postitem}`}>
	                    <a
	                      href={`/post?id=${post.id}`}
	                      target={post.openNewTab ? '_blank' : '_self'}
	                      rel="noreferrer"
	                    >
	                      {post.image ? (
	                        <img
	                          className={`${style.postimg}`}
	                          src={post.image ? imageUrl(post.image.url) : ''}
	                        />
	                      ) : null}
	                      <p>{post.title}</p>
	                      <span>{this.parseDate(post.created_at)}</span>
	                    </a>
	                  </div>
	                );
	              }
							  })
	            : null}
	        </div>
	      </div>
	    </div>
	  );
	};
}

const LatestArticlesComp = connect(['posts'])(LatestArticles);
export default LatestArticlesComp;
