/* eslint-disable react/prefer-stateless-function */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import { h, render, Component } from 'preact';
import style from './style';

class Footer extends Component {
	render = (props) => {
	  return (
	    <div className={`${style.footercomp}`}>
	      <div className={`${style.container}`}>
	        {props.sections && props.sections.length > 0
	          ? props.sections.map((section, i) => {
	            return (
	              <div className={`${style.footersection}`}>
	                <h3 className={`${style.footertitle}`}>{section.title}</h3>
	                {section.description && section.description.length > 0
	                  ? section.description.map((desc, i) => {
	                    return (
	                      <div className={`${style.desc}`}>
	                        <h4>{desc.title}</h4>
	                        <p>{desc.text}</p>
	                      </div>
	                    );
											  })
	                  : null}
	              </div>
	            );
						  })
	          : null}
	      </div>
	    </div>
	  );
	};
}

export default Footer;
