/* eslint-disable import/extensions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-unused-vars */
import { h, render, Component } from 'preact';
import { replaceUrlPlaceholders, imageUrl } from '_helpers';
import style from './style';

class Background extends Component {
	render = (props) => {
	  return (
	    <div className={`${style.backgroundcon}`}>
	      <img
	        className={`${style.topbg}`}
	        src={props.topImage ? imageUrl(props.topImage) : ''}
	      />
	    </div>
	  );
	};
}

export default Background;
