/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-nested-ternary */
import { h, Component } from 'preact';
import { imageUrl } from '_helpers';
// eslint-disable-next-line import/extensions
import style from './style';

class Qrcode extends Component {
	render = (props) => {
	  return (
	    <div id={props.id || ''} className={`${style.qrsection}`}>
	      <div className={`${style.container}`}>
	        <div className={`${style.gradient}`}>
	          <div className={`${style.container}`}>
	            <div className={`${style.left}`}>
	              <div>
	                {props.title.type === 'text' ? (
	                  <h1>{props.title.text}</h1>
	                ) : null}
	                {props.button
	                  ? props.button.map((btn) => {
	                    return (
	                      <div className={`${style.buttons}`}>
	                        <a
	                          href={btn.link.url}
	                          target={
	                            btn.link.openInNewTab ? '_blank' : '_self'
	                          }
	                          rel="noreferrer"
	                        >
	                          {btn.icon ? (
	                            btn.icon.type === 'image' ? (
	                              <img src={btn.icon.src} />
	                            ) : btn.icon.type === 'icon' ? (
	                              <span className={btn.icon.name} />
	                            ) : null
	                          ) : null}
	                          {btn.text ? <span>{btn.text}</span> : null}
	                        </a>
	                      </div>
	                    );
										  })
	                  : null}
	              </div>
	            </div>
	            <div className={`${style.right}`}>
	              <div className={`${style.qrcon}`}>
	                {props.qrImage ? (
	                  <div className={`${style.qr}`}>
	                    <div>
	                      <span />
	                      <span />
	                      <span />
	                      <span />
	                    </div>

	                    <img
	                      id={props.qrImage.id || ''}
	                      src={imageUrl(props.qrImage.src)}
	                    />
	                  </div>
	                ) : null}
	                {props.image ? (
	                  <img
	                    id={props.image.id || ''}
	                    src={imageUrl(props.image.src)}
	                  />
	                ) : null}
	              </div>
	            </div>
	          </div>
	        </div>
	      </div>
	    </div>
	  );
	};
}

export default Qrcode;
