/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import { h, render, Component } from 'preact';
import { connect } from 'unistore/preact';
import { imageUrl } from '_helpers';
import style from './style';

// IMPORT SLIDER
import Slider from '@bit/akiran.react-slick.slider';
import '../../../style/lib/slickslider';

class FeaturedCarousel extends Component {
	componentDidMount = () => {};

	componentDidUpdate = () => {
	  const { featuredPosts } = this.props;
	  // eslint-disable-next-line no-console
	  console.warn('FeaturedCarousel data >>', featuredPosts);
	};

	render = (props) => {
	  const { autoplay, slideInterval, featuredPosts, showContent } = this.props;
	  const settings = {
	    dots: true,
	    infinite: true,
	    speed: 500,
	    arrows: false,
	    slidesToShow: 1,
	    slidesToScroll: 1,
	    pauseOnHover: true,
	    autoplay,
	    autoplaySpeed: slideInterval * 1000
	  };

	  return (
	    <div className={`${style.featuredCarousel}`}>
	      <div className={`${style.container}`}>
	        <Slider {...settings}>
	          {featuredPosts.data
	            ? featuredPosts.data.map((slide, i) => {
	              return (
	                <div className={`${style.slide}`}>
	                  {slide.image ? (
	                    <a
	                      href={`/post?id=${slide.id}`}
	                      target={slide.openNewTab ? '_blank' : '_self'}
	                      rel="noreferrer"
	                    >
	                      <img
	                        className={`${style.slideimg}`}
	                        src={slide.image ? imageUrl(slide.image.url) : ''}
	                      />
	                    </a>
	                  ) : null}
	                  {showContent ? (
	                    <div className={`${style.slideContent}`}>
	                      <div className={`${style.left}`}>
	                        {slide.title ? <h1>{slide.title}</h1> : null}
	                        {slide.ShortDescription ? (
	                          <p>{slide.ShortDescription}</p>
	                        ) : slide.content ? (
	                          <p>{slide.content}</p>
	                        ) : null}
	                      </div>
	                      <div className={`${style.right}`}>
	                        <div className={`${style.tags}`}>
	                          {slide.tags
	                            ? slide.tags.map((tag, i) => {
	                              return (
	                                <button
	                                  type="button"
	                                  className={`${style.tag}`}
	                                >
	                                  {tag.name}
	                                </button>
	                              );
																  })
	                            : null}
	                        </div>

	                        <a
	                          href={`/post?id=${slide.id}`}
	                          target={slide.openNewTab ? '_blank' : '_self'}
	                          rel="noreferrer"
	                        >
	                          {slide.linkText}
	                        </a>
	                      </div>
	                    </div>
	                  ) : null}
	                </div>
	              );
							  })
	            : null}
	        </Slider>
	      </div>
	    </div>
	  );
	};
}

const ConnectComp = connect(['featuredPosts'])(FeaturedCarousel);
export default ConnectComp;
