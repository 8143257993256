/* eslint-disable react/jsx-props-no-spreading */
import { h, Component } from 'preact';
import { FormSample } from '_components/core';
// eslint-disable-next-line import/extensions
import style from './style';

// eslint-disable-next-line react/prefer-stateless-function
class Form extends Component {
	render = (props) => {
	  // eslint-disable-next-line react/destructuring-assignment
	  return (
	    <div style={style.defaultForm}>
	      <FormSample {...props} partnerId="d0f715ade37d8bbb8a5f175d0d23064e" />
	    </div>
	  );
	};
}
export default Form;
