/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/state-in-constructor */
import { h, render, Component } from 'preact';
import { Router } from 'preact-router';
import { Link } from 'preact-router/match';
import { connect } from 'unistore/preact';
import { store } from '_unistore';
import { imageUrl } from '_helpers';
import { setLanguage } from '_mutations';
// eslint-disable-next-line import/extensions
import style from './style';

class LanguageSwitch extends Component {
	state = {
	  fetching: true,
	  activeLanguage: null,
	  openSelect: false,
	  data: null
	};

	componentDidMount = () => {
	  // eslint-disable-nextline
	  console.warn('LanguageSwitch data >>', this.props, this.props.menu);
	  this.setData();
	};

	componentDidUpdate = () => {
	  // eslint-disable-nextline
	  console.warn('LanguageSwitch data >>', this.state, this.props);
	  this.setData();
	};

	setData = () => {
	  const globalState = store.getState();
	  // eslint-disable-nextline
	  console.warn('LanguageSwitch Comp >>', this.state, this.props);
	  if (this.state.data === null && this.props.languages.data) {
	    this.setState({
	      fetching: false,
	      activeLanguage: globalState.selectedLanguage,
	      data: this.props.languages.data
	    });
	  }
	};

	openLangSelect = () => {
	  this.setState({
	    openSelect: !this.state.openSelect
	  });
	};

	changeLanguage = (langCode) => {
	  setLanguage(langCode);
	  this.setState({
	    data: null,
	    openSelect: false,
	    fetching: true
	  });
	};

	render = (props) => {
	  if (!this.state.fetching && this.state.data) {
	    return (
	      <div data-type="langswitch" className={style.langswitch}>
	        {this.state.data
	          ? this.state.data.map((item, i) => {
	            if (item.name.toLowerCase() === this.state.activeLanguage) {
	              return (
	                <div
	                  className={style.drop}
	                  onClick={() => {
	                    this.openLangSelect();
	                  }}
	                  style={`background-image:url(${imageUrl(item.flag.url)})`}
	                />
	              );
	            }
						  })
	          : null}
	        <div
	          className={style.langdropdown}
	          style={
	            this.state.openSelect
	              ? 'max-height: 700px;'
	              : 'max-height:0px; border:none;'
	          }
	        >
	          {this.state.data
	            ? this.state.data.map((item, i) => {
	              return (
	                <span
	                  onClick={() => {
	                    this.changeLanguage(item.name.toLowerCase());
	                  }}
	                  selected={this.state.activeLanguage === item.code}
	                  data-lang={item.code}
	                  style={`background-image:url(${imageUrl(item.flag.url)})`}
	                />
	              );
							  })
	            : null}
	        </div>
	      </div>
	    );
	  }
	};
}

const LangComp = connect(['languages'])(LanguageSwitch);
export default LangComp;
