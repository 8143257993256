/* eslint-disable radix */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { h, render, Component } from 'preact';
import { connect } from 'unistore/preact';
import { imageUrl } from '_helpers';
import { Stories } from '_components/grid';
// eslint-disable-next-line import/extensions
import style from './style';

class PostContent extends Component {
	componentDidMount = () => {
	  console.warn('PostContent data >>', this.props);
	};

	componentDidUpdate = () => {
	  console.warn('PostContent data >>', this.props);
	};

	parseDate = (date) => {
	  const d = new Date(date);
	  let month = `${d.getMonth() + 1}`;
	  let day = `${d.getDate()}`;
	  const year = d.getFullYear();

	  if (month.length < 2) month = `0${month}`;
	  if (day.length < 2) day = `0${day}`;

	  return [year, month, day].join('/');
	};

	getPostTags = (post) => {
	  const tags = [];
	  if (post) {
	    post.tags &&
				post.tags.map((tag, i) => {
				  tags.push(tag.name);
				});

	    return tags.join(',');
	  }
	  return null;
	};

	getUrlParameter = (name) => {
	  // eslint-disable-next-line no-useless-escape, no-param-reassign
	  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
	  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
	  const results = regex.exec(location);
	  return results === null
	    ? ''
	    : decodeURIComponent(results[1].replace(/\+/g, ' '));
	};

	render = (props) => {
	  const id = this.getUrlParameter('id');
	  return (
	    <div className={`${style.postcon}`}>
	      <div className={`${style.container}`}>
	        {this.props.posts.data
	          ? this.props.posts.data.map((post, i) => {
	            if (post.id === parseInt(id)) {
	              const { content } = post;
	              return (
	                <div className={style.left}>
	                  <div className={style.title}>
	                    <h1>{post.title}</h1>
	                    <span>{this.parseDate(`${post.created_at}`)}</span>
	                    <div className={style.tags}>
	                      {post.tags.map((tag, i) => {
	                        return <span>{tag.name}</span>;
	                      })}
	                    </div>
	                  </div>
	                  <div className={`${style.content}`}>
	                    <div>
	                      <img
	                        src={post.image ? imageUrl(post.image.url) : ''}
	                      />
	                      <div dangerouslySetInnerHTML={{ __html: content }} />
	                    </div>
	                  </div>
	                </div>
	              );
	            }
						  })
	          : null}
	        <div className={style.right}>
	          <p>{this.props.stories.title ? this.props.stories.title : ''}</p>
	          <Stories
	            itemsToDisplay={this.props.stories.itemsToDisplay}
	            displayTags={this.props.stories.displayTags}
	            button={this.props.button}
	          />
	        </div>
	      </div>
	    </div>
	  );
	};
}

const PostContentComp = connect(['posts'])(PostContent);
export default PostContentComp;
