/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-console */
/* eslint-disable react/state-in-constructor */
/* eslint-disable no-unused-vars */
import { h, render, Component } from 'preact';
import { connect } from 'unistore/preact';
import { imageUrl } from '_helpers';
// eslint-disable-next-line import/extensions
import style from './style';

class Stories extends Component {
	state = {
	  selectedTag: 'All'
	};

	componentDidMount = () => {
	  console.error(this.props);
	};

	componentDidUpdate = () => {
	  this.displayTaggedItems(this.state.selectedTag);
	  console.error(this.props);
	};

	parseDate = (date) => {
	  const d = new Date(date);
	  let month = `${d.getMonth() + 1}`;
	  let day = `${d.getDate()}`;
	  const year = d.getFullYear();

	  if (month.length < 2) month = `0${month}`;
	  if (day.length < 2) day = `0${day}`;

	  return [year, month, day].join('/');
	};

	getPostTags = (post) => {
	  const tags = [];
	  if (post) {
	    post.tags &&
				post.tags.map((tag, i) => {
				  tags.push(tag.name);
				});

	    return tags.join(',');
	  }
	  return null;
	};

	selectTag = (tag) => {
	  this.setState({
	    selectedTag: tag
	  });
	};

	displayTaggedItems = (tag) => {
	  try {
	    const elem = document.querySelectorAll('[data-post]');
	    elem.forEach((item) => {
	      const itemTags = item.dataset.tags.split(',');
	      if (itemTags.indexOf(tag) > -1) {
	        item.style.display = 'inline-block';
	      } else {
	        item.style.display = 'none';
	      }
	    });
	  } catch (err) {
	    console.error('displayTaggedItems', err);
	  }
	};

	render = (props) => {
	  return (
	    <div className={`${style.stories}`}>
	      <div className={`${style.container}`}>
	        {this.props.displayTags ? (
	          <div className={`${style.tags}`}>
	            {this.props.tags.data
	              ? this.props.tags.data.map((tag, i) => {
	                return (
	                  <button
	                    type="button"
	                    onClick={(e) => this.selectTag(tag.name)}
	                    style={
	                      this.state.selectedTag === tag.name
	                        ? 'opacity:1; background:#4a8eb9'
	                        : ''
	                    }
	                  >
	                    {tag.name}
	                  </button>
	                );
								  })
	              : null}
	          </div>
	        ) : null}
	        <div>
	          {this.props.posts.data
	            ? this.props.posts.data.map((post, i) => {
	              if (i + 1 <= this.props.itemsToDisplay) {
	                return (
	                  <div
	                    className={`${style.postitem}`}
	                    data-tags={this.getPostTags(post)}
	                    data-post
	                  >
	                    <a
	                      href={`/post?id=${post.id}`}
	                      target={post.openNewTab ? '_blank' : '_self'}
	                      rel="noreferrer"
	                    >
	                      {post.image ? (
	                        <div
	                          className={`${style.postimage}`}
	                          style={
	                            post.image
	                              ? `background-image:url(${imageUrl(
	                                post.image.url
																	  )})`
	                              : `background-image:url(${imageUrl(
	                                '/uploads/5bf8f7254a8045bf9390a57c964a28a2.jpg'
																	  )})`
	                          }
	                        />
	                      ) : null}

	                      <div className={`${style.postdetail}`}>
	                        <p>{post.title}</p>
	                        <span>{this.parseDate(post.created_at)}</span>
	                      </div>
	                    </a>
	                  </div>
	                );
	              }
							  })
	            : null}
	        </div>
	        {this.props.button
	          ? this.props.button.map((btn, i) => {
	            return (
	              <a
	                className={`${style.btnmore}`}
	                href={btn.link.url}
	                target={btn.link.openInNewTab ? '_blank' : '_self'}
	                rel="noreferrer"
	              >
	                {btn.text}
	              </a>
	            );
						  })
	          : null}
	      </div>
	    </div>
	  );
	};
}

const StoriesComp = connect(['posts', 'tags'])(Stories);
export default StoriesComp;
