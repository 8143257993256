/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable react/state-in-constructor */
/* eslint-disable import/extensions */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
import { h, render, Component } from 'preact';
import style from './style';
import { imageUrl, replaceUrlPlaceholders, getTranslation } from '_helpers';

class HeroBanner extends Component {
	state = {
	  windowWidth: null
	};

	resizeEventHandler = () => {
	  this.setState({ windowWidth: window ? window.innerWidth : null });
	};

	scrollDownToNextSection = (duration) => {
	  const scrollpx = document.getElementById(`${this.props.id}`).offsetHeight;
	  const scrollStep = scrollpx / (duration / 15);
	  const scrollInterval = setInterval(() => {
	    if (window.scrollY <= scrollpx) {
	      window.scrollBy(0, scrollStep);
	    } else clearInterval(scrollInterval);
	  }, 15);
	};

	componentDidMount = () => {
	  // ! set current window inner width
	  this.setState({ windowWidth: window ? window.innerWidth : null });

	  // ! add resize listener
	  window.addEventListener('resize', this.resizeEventHandler);
	};

	render = (props) => {
	  return (
	    <div id={props.id} className={`${style.herobanner}`}>
	      <div className={`${style.container}`}>
	        <div id="hb_left" className={`${style.left}`}>
	          <div>
	            {/* TITLE */}
	            {props.title ? (
	              <div id="hb_title" className={`${style.hbtitle}`}>
	                {props.title.type === 'text' ? (
	                  <h1>{props.title.text}</h1>
	                ) : null}
	                {props.title.type === 'image' ? (
	                  <img
	                    src={props.title.src ? imageUrl(props.title.src) : ''}
	                  />
	                ) : null}
	              </div>
	            ) : null}

	            {/* DESCRIPTION */}
	            {props.text ? (
	              <p id="hb_desc" className={`${style.description}`}>
	                {props.text.text}
	              </p>
	            ) : null}

	            {/* IMAGE - SHOULD ONLY DISPLAY THIS ON MOBILE DEVICES WITH SCREENS < 1024PX */}
	            {this.state.windowWidth <= 960 ? (
	              <div id="hb_images" className={`${style.leftimgcon}`}>
	                {props.images && props.images.length > 0 ? (
	                  <div className={`${style.imgcon}`}>
	                    {props.images.map((img, i) => {
	                      return (
	                        <img
	                          className={`${i}`}
	                          src={img ? imageUrl(img) : ''}
	                        />
	                      );
	                    })}
	                  </div>
	                ) : null}
	              </div>
	            ) : null}

	            {/* BUTTONS */}
	            {props.buttons ? (
	              <div id="hb_buttons" className={`${style.buttons}`}>
	                {props.buttons.map((btn, i) => {
	                  return (
	                    <a
	                      href={replaceUrlPlaceholders(btn.link.url)}
	                      target={btn.link.openInNewTab ? '_blank' : '_self'}
	                      rel="noreferrer"
	                    >
	                      {btn.icon ? (
	                        btn.icon.type === 'image' ? (
	                          <img
	                            src={btn.icon.src ? imageUrl(btn.icon.src) : ''}
	                          />
	                        ) : btn.icon.type === 'icon' ? (
	                          <span className={btn.icon.name} />
	                        ) : null
	                      ) : null}
	                      {btn.text ? <span>{btn.text}</span> : null}
	                      {btn.isNew ? (
	                        <span className={style.newTag}>
	                          {btn.newText || getTranslation('LABEL_NEW')}
	                        </span>
	                      ) : null}
	                    </a>
	                  );
	                })}
	              </div>
	            ) : null}
	          </div>
	        </div>

	        {/* RIGHT PANEL - SHOULD ONLY DISPLAY ON SCREENS > 1024PX */}
	        {this.state.windowWidth > 960 ? (
	          <div id="hb_right" className={`${style.right}`}>
	            <div id="hb_images">
	              {props.images && props.images.length > 0 ? (
	                <div className={`${style.imgcon}`}>
	                  {props.images.map((img, i) => {
	                    return (
	                      <img
	                        className={`${i}`}
	                        src={img ? imageUrl(img) : ''}
	                      />
	                    );
	                  })}
	                </div>
	              ) : null}
	            </div>
	          </div>
	        ) : null}
	      </div>

	      <button
	        id="hb_downarrow"
	        className={`${style.downarrow}`}
	        onClick={() => {
	          this.scrollDownToNextSection(250);
	        }}
	      />
	    </div>
	  );
	};
}

export default HeroBanner;
