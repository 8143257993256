/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/state-in-constructor */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import { h, render, Component } from 'preact';
import { Router } from 'preact-router';
import { Link } from 'preact-router/match';
import { connect } from 'unistore/preact';
import { imageUrl } from '_helpers';
import { LanguageSwitch } from '_components/grid';

import style from './style';

class Navigation extends Component {
	state = {
	  fetching: true,
	  data: null,
	  mobileMenuOpen: false
	};

	componentDidMount = () => {
	  // eslint-disable-next-line no-console
	  console.warn('Navigation data >>', this.props, this.props.menu);
	  this.setData();
	};

	componentDidUpdate = () => {
	  this.setData();
	  // eslint-disable-next-line no-console
	  console.warn('Navigation data >>', this.state, this.props);
	};

	setData = () => {
	  // eslint-disable-next-line react/destructuring-assignment
	  if (this.state.data === null && this.props.menu.data) {
	    // eslint-disable-next-line react/destructuring-assignment
	    const menu = this.props.menu.data[0];
	    this.setState({
	      fetching: false,
	      data: menu.config
	    });
	  }
	};

	scrollTo = (e, id) => {
	  e.preventDefault();
	  try {
	    document.getElementById(id.replace('#', '')).scrollIntoView({
	      behavior: 'smooth'
	    });
	    this.setState({
	      mobileMenuOpen: false
	    });
	  } catch (err) {
	    // eslint-disable-next-line no-console
	    console.log('Navigation >> scrollTo >> Error', err);
	  }
	};

	renderScrollToLink = (item) => {
	  return (
	    <Link
	      activeClassName={style.active}
	      href={item.url}
	      onClick={(e) => {
	        this.scrollTo(e, item.linkType.id);
	      }}
	    >
	      {item.text}
	    </Link>
	  );
	};

	renderLink = (item) => {
	  return (
	    <Link
	      activeClassName={style.active}
	      href={item.url}
	      target={item.openInNewTab ? '_blank' : '_self'}
	    >
	      {item.text}
	    </Link>
	  );
	};

	renderExternalLink = (item) => {
	  return (
	    <a
	      className={style.active}
	      href={item.url}
	      target={item.openInNewTab ? '_blank' : '_self'}
	      rel="noreferrer"
	    >
	      {item.text}
	    </a>
	  );
	};

	render = (props) => {
	  if (!this.state.fetching && this.state.data) {
	    return (
	      <div
	        className={
	          this.state.mobileMenuOpen
	            ? `${style.navigation} ${style.open}`
	            : `${style.navigation}`
	        }
	      >
	        <div className={style.container}>
	          <div
	            className={
	              this.state.mobileMenuOpen
	                ? `${style.toggle} ${style.open}`
	                : `${style.toggle}`
	            }
	            onClick={() => {
	              this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen });
	            }}
	          >
	            <span />
	            <span />
	            <span />
	          </div>
	          <nav>
	            {this.state.data.map((item, i) => {
	              if (item.linkType && item.linkType.type === 'scrollto') {
	                return this.renderScrollToLink(item);
	              }
	              if (item.linkType && item.linkType.type === 'external') {
	                return this.renderExternalLink(item);
	              }
	              return this.renderLink(item);
	            })}
	          </nav>
	          {this.props.logo ? (
	            <img className={style.logo} src={imageUrl(this.props.logo.src)} />
	          ) : null}

	          <LanguageSwitch />
	        </div>
	      </div>
	    );
	  }
	  return null;
	};
}

const NavComp = connect(['menu'])(Navigation);
export default NavComp;
